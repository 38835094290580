import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next';


import Header from '../../components/header/Header';
import AdventureList from '../../components/adventures/adventureList/AdventureList';
import PhotographyList from '../../components/photography/photographyList/PhotographyList';
import SectionBox from '../../components/sectionBox/SectionBox';
import BannerApp from "../../components/bannerApp/bannerApp";
import Button from "../../components/button/Button";
import NewsletterBox from "../../components/NewsletterBox/NewsletterBox";
import NewsletterHeaderBox from "../../components/NewsletterHeaderBox/NewsletterHeaderBox";
import { blogName, photo } from "../../constants";

import {
  HomeBrandName,
  HomeWrapper,
  HomeSectionHeader,
  HomeBoxTitle,
  HomeBoxPreTitle,
  HomeBoxSeparator,
  HomeBox,
  HomeBoxPurpose,
  HomeSectionTitle,
  HomeSection,
  HomeSectionSubtitle,
  HomeSectionButtonWrapper,
  Space,
  HeaderClaimWrapper,
  HeaderClaimText,
  HeaderSpecialText,
  HomeBoxSubtitle,
  HomeSectionTitleWrapper,
  HomeSectionViewAll,
  HomeSectionInfoText,
  HomeSectionInfo,
  HomeCopyBox,
  HomeSpecialText,
  HomeCopyText,
  HomeSpace,
  HomeBoxPurposeBrand,
  HomeCopyBrand,
  HomeBoxTitleBold,
  HomeBoxPurposeDetails,
  HomeBoxPurposeDetailsTravel,
  HomeBoxPurposeDetailsChange,
  NewsletterHeaderBoxWrapper,
  HeaderSpecialHumanText,
  HomeBoxPainPointText,
  HomeBoxPainPointTextItalic,
  HomeBoxPainPointTextSpecial,
  HomeBoxPainPointTextWrapper,
  HomeBoxPainPointFormWrapper,
  HomeBoxPainPoint,
  NewsletterExample,
  NewsletterExampleText,
  NewsletterExampleBlack,
  NewsletterExamplRed
} from './Home.styles'

import header from './header.jpg';

const Home = () => {
  const history = useHistory()

  const { t } = useTranslation()

  const monthsArr = [
    [1, 'Uno'],
    [2, 'Dos'],
    [3, 'Tres'],
    [4, 'Cuatro'],
    [5, 'Cinco'],
    [6, 'Seis'],
    [7, 'Siete'],
    [8, 'Ocho'],
    [9, 'Nueve'],
    [10, 'Diez'],
    [11, 'Once'],
    [12, 'Doce'],
    [13, 'Trece'],
    [14, 'Catorce'],
    [15, 'Quince'],
    [16, 'Dieciséis'],
    [17, 'Diecisiete'],
    [18, 'Dieciocho'],
    [19, 'Diecinueve'],
    [20, 'Veinte'],
    [21, 'Veintiuno'],
    [22, 'Veintidós'],
    [23, 'Veintitrés'],
    [24, 'Veinticuatro'],
    [25, 'Veinticinco'],
    [26, 'Veintiséis'],
    [27, 'Veintisiete'],
    [28, 'Veintiocho'],
    [29, 'Veintinueve'],
    [30, 'Treinta'],
    [31, 'Treinta y uno'],
    [32, 'Treinta y dos'],
    [33, 'Treinta y tres'],
  ]

  const startedTravelDate = new Date('2023-08-01');
  const currDate = new Date()
  const elapsedMonths = (currDate.getFullYear() - startedTravelDate.getFullYear()) * 12 + (currDate.getMonth() - startedTravelDate.getMonth());
  const [monthNumInt, monthNumText] = monthsArr[elapsedMonths - 1]

  const [isExampleVisible, setIsExampleVisible] = useState(false)

  return (
    <HomeWrapper>
      <Header imageSrc={header} isHeighAuto={isExampleVisible}>
        <HeaderClaimWrapper>
          <HeaderClaimText>
          <HeaderSpecialText>
            Si Siempre Quisiste Saber Cómo es un Gran Viaje por el Mundo
          </HeaderSpecialText> 
          {/* {t('home.header.middle')} */}
          <HeaderSpecialHumanText>
            Síguenos, nosotros te lo mostramos.
          {/* {t('home.header.end')} */}
          </HeaderSpecialHumanText> 
          </HeaderClaimText>
          <NewsletterHeaderBoxWrapper>
            <NewsletterHeaderBox isHeader={true} setIsExampleVisible={setIsExampleVisible} isExampleVisible={isExampleVisible} />
            {
              isExampleVisible && (
                <NewsletterExample>
    <NewsletterExampleText><NewsletterExampleBlack>Piensa por un segundo.</NewsletterExampleBlack></NewsletterExampleText>

    <NewsletterExampleText>¿Qué hiciste en los últimos 7 días?</NewsletterExampleText>


    <NewsletterExampleText>¿Ya?</NewsletterExampleText>


    <NewsletterExampleText>Quiero compartirte nuestra última experiencia de viaje. </NewsletterExampleText>

    <NewsletterExampleText>Esta se mezcla con la realidad que vemos en las noticias.</NewsletterExampleText>

    <NewsletterExampleText>Estoy seguro de que te interesa. </NewsletterExampleText>

    <NewsletterExampleText>Viajes con destinos diferentes.</NewsletterExampleText>


    <NewsletterExampleText><NewsletterExamplRed>Te cuento.</NewsletterExamplRed></NewsletterExampleText>


    <NewsletterExampleText>Contemplo un hermoso atardecer sobre la inmensidad del río Amazonas.</NewsletterExampleText>
    <NewsletterExampleText>Luego, a lo lejos, destellos de tormenta.</NewsletterExampleText>
    <NewsletterExampleText>A la noche nos alcanza.</NewsletterExampleText>
    <NewsletterExampleText>La lluvía entra en el barco.</NewsletterExampleText>
    <NewsletterExampleText>La misma lluvía que amamanta la eterna vegetación que nos rodea.</NewsletterExampleText>
    <NewsletterExampleText>Un tripulante empuja el agua sobre el metal fuera de la borda, hacia al río.</NewsletterExampleText>

    <NewsletterExampleText>Es la hora de la cena.</NewsletterExampleText>
    <NewsletterExampleText>La maternidad lo inunda todo. Jóvenes mujeres amamantan a sus criaturas rechonchas inmersas en el suave balanceo de la hamaca.</NewsletterExampleText>
    <NewsletterExampleText>Niños corren y juegan.</NewsletterExampleText>

    <NewsletterExampleText>Nosotros esperamos en la cola a nuestro turno para el arroz, las alubias, los espaguetis y la porción de carne, hoy toca guiso.</NewsletterExampleText>

    <NewsletterExampleText>Es como un viaje dentro de un viaje, pienso. Siete días remontando el Amazonas. Sin conexión con el exterior. Aquí hay espacio para el aburrimiento.</NewsletterExampleText>

    <NewsletterExampleText>Nos sentamos a la mesa.</NewsletterExampleText>

    <NewsletterExampleText>Sobre el guiso rocío farofa (harina de yuca tostada). Espesa la salsa. </NewsletterExampleText>
    <NewsletterExampleText>De pronto, una mujer con media melena, gris, tez morena, ojos grandes y redondos, con largas pestañas y algo de bigote se acerca y pregunta:</NewsletterExampleText>

    <NewsletterExampleText>¿Vais a cruzar la selva?</NewsletterExampleText>

    <NewsletterExampleText>Se refiere al Tapón del Darién, la densa selva compartida entre Colombia y Panamá.</NewsletterExampleText>
    <NewsletterExampleText>Paso obligado para los migrantes en su huida a Estados Unidos, antes de las elecciones de noviembre 2024, dice. </NewsletterExampleText>

    <NewsletterExampleText>Viaja sola. Por eso pregunta. Quiere encontrar acompañantes.</NewsletterExampleText>


    <NewsletterExampleText>Nosotros subimos al barco en busca de aventuras. Ella en busca de una vida mejor.</NewsletterExampleText>


    <NewsletterExampleText>Para las jóvenes mujeres y sus criaturas el viaje acaba en alguno de los puertos donde el barco hace amarre para descargar mercancía, para nosotros y la mujer migrante perdurará un tiempo más, aunque con destinos diferentes.</NewsletterExampleText>


    <NewsletterExampleText>Ten un fantástico día, </NewsletterExampleText>
    <NewsletterExampleText>Rodrigo </NewsletterExampleText>
            <HomeBoxPainPointFormWrapper>
              <NewsletterHeaderBox/>
            </HomeBoxPainPointFormWrapper>

                </NewsletterExample>
              )
            }

          </NewsletterHeaderBoxWrapper>
        </HeaderClaimWrapper>
      </Header>

      <HomeBoxPainPoint>
        <HomeBoxPainPointText>{t('home.presentation.painPoint0')}</HomeBoxPainPointText>
        <HomeBoxPainPointText>{t('home.presentation.painPoint1')}</HomeBoxPainPointText>
        <HomeBoxPainPointText>{t('home.presentation.painPoint2')}</HomeBoxPainPointText>
        <HomeBoxPainPointText>{t('home.presentation.painPoint3')}</HomeBoxPainPointText>
        <HomeBoxPainPointText>{t('home.presentation.painPoint4')}</HomeBoxPainPointText>
        <HomeBoxPainPointText>{t('home.presentation.painPoint5')}</HomeBoxPainPointText>
        <HomeBoxPainPointText>{t('home.presentation.painPoint6')}</HomeBoxPainPointText>
        <HomeBoxPainPointTextWrapper>
          <HomeBoxPainPointText>
            <Trans i18nKey="home.presentation.painPoint7" components={{ italic: <HomeBoxPainPointTextItalic /> }}/>
          </HomeBoxPainPointText>
          <HomeBoxPainPointText>
            <Trans i18nKey="home.presentation.painPoint8" components={{ italic: <HomeBoxPainPointTextItalic /> }}/>
          </HomeBoxPainPointText>
        </HomeBoxPainPointTextWrapper>
        <HomeBoxPainPointText>{t('home.presentation.painPoint9')}</HomeBoxPainPointText>
        <HomeBoxPainPointText>{t('home.presentation.painPoint10')}</HomeBoxPainPointText>
        <HomeBoxPainPointTextSpecial>{t('home.presentation.painPoint11')}</HomeBoxPainPointTextSpecial>
        <HomeBoxPainPointFormWrapper>
          <NewsletterHeaderBox/>
        </HomeBoxPainPointFormWrapper>
      </HomeBoxPainPoint>
      <HomeBox>
        <HomeBoxPreTitle>{t('home.presentation.welcome')} </HomeBoxPreTitle>
        <HomeBrandName translate='no'>Empty Road Project</HomeBrandName>
        <HomeBoxSeparator />
        <HomeBoxTitle>
          <Trans i18nKey="home.presentation.travelFrom" components={{ bold: <HomeBoxTitleBold /> }}/>
        </HomeBoxTitle>
        
        <HomeBoxSubtitle>
          <HomeBoxPurposeDetails>
            <HomeBoxPurposeDetailsChange>
              {monthNumText}
            </HomeBoxPurposeDetailsChange>
            {(' ')}
            (<HomeBoxPurposeDetailsChange>{monthNumInt}</HomeBoxPurposeDetailsChange>)
            {(' ')}
            meses van de viaje
          </HomeBoxPurposeDetails>
          <HomeBoxPurposeDetailsTravel>
            Ecuador - Perú - Bolivia - Chile - Argentina - Brasil - Colombia - Panamá - Costa Rica - Nicaragua - El Salvador
          </HomeBoxPurposeDetailsTravel>

          <HomeBoxPurpose>
          {t('home.presentation.started')}
          </HomeBoxPurpose>
        </HomeBoxSubtitle>
        {/* <Button 
          width={"300px"}
          onClick={() => history.push('/about')}
          text="Quiero conocer la historia"
        /> */}
      </HomeBox>


      {/* <HomeCopyBox> */}
          {/* <HomeCopyText>
            <Trans i18nKey="home.persuasiveText.1.notOnlyTravel" components={{ bold: <b /> }}/>
          </HomeCopyText>
          <HomeCopyText>{t('home.persuasiveText.1.fromSpainToPoland')}</HomeCopyText>
          <HomeCopyText>{t('home.persuasiveText.1.travelFrom')}</HomeCopyText>
          <HomeSpace />
          <HomeSpace />
          <HomeCopyText>
            <Trans i18nKey="home.persuasiveText.1.travelWithPurpose" components={{ bold: <b /> }}/>
          </HomeCopyText>
          <HomeSpace />
          <HomeSpace />
          <HomeCopyText>{t('home.persuasiveText.1.questionTravelWithPurpose')}</HomeCopyText>
          <HomeSpace />
          <HomeSpace />
          <HomeCopyText>{t('home.persuasiveText.1.imagingPhoto')}</HomeCopyText>
          <HomeCopyText>{t('home.persuasiveText.1.imagingPhotoAction')}</HomeCopyText>
          <HomeSpace />
          <HomeCopyText>{t('home.persuasiveText.1.imagingSpeak')}</HomeCopyText>
          <HomeCopyText>{t('home.persuasiveText.1.imagingSpeakAction')}</HomeCopyText>
          <HomeSpace />
          <HomeCopyText>{t('home.persuasiveText.1.imagingOther')}</HomeCopyText>
          <HomeCopyText>
            <Trans i18nKey="home.persuasiveText.1.enrichness" components={{ bold: <b /> }}/>
          </HomeCopyText>
          <HomeCopyText>{t('home.persuasiveText.1.relationWithLocals')}</HomeCopyText>
          <HomeCopyText>{t('home.persuasiveText.1.buySell')}</HomeCopyText>
          <HomeCopyText>
            <Trans i18nKey="home.persuasiveText.1.enrichLife" components={{ bold: <b /> }}/>
          </HomeCopyText>
          <HomeCopyText>{t('home.persuasiveText.1.living')}</HomeCopyText>
          <HomeCopyText><HomeCopyBrand translate='no'>Empty Road Project </HomeCopyBrand> {t('home.persuasiveText.1.aStepForward')}</HomeCopyText>
          <HomeCopyText>{t('home.persuasiveText.1.ifYouLikeIt')}</HomeCopyText> */}

          {/* <NewsletterBox /> */}

          {/* <HomeCopyText>{t('home.persuasiveText.2.average')}</HomeCopyText>
          <HomeCopyText>
            <Trans i18nKey="home.persuasiveText.2.doubts" components={{ bold: <b /> }}/>
          </HomeCopyText>
          <HomeCopyText>{t('home.persuasiveText.2.inTheView')}</HomeCopyText>
          <HomeCopyText>{t('home.persuasiveText.2.inTheAventura')}</HomeCopyText>
          <HomeCopyText>{t('home.persuasiveText.2.enjoy')}</HomeCopyText>
          <HomeCopyText>{t('home.persuasiveText.2.onlyFor')}</HomeCopyText> */}
          {/* <HomeCopyText>{t('home.persuasiveText.2.lessTalkMoreDo')}</HomeCopyText> */}

          {/* <NewsletterBox /> */}
        {/* </HomeCopyBox> */}
      <HomeSection>
        <HomeSectionHeader onClick={() => history.push('/photography')}>
           <HomeSectionTitleWrapper>
            <HomeSectionTitle>{photo}</HomeSectionTitle>
            <HomeSectionViewAll>{t('home.blog.viewAll')}</HomeSectionViewAll>
          </HomeSectionTitleWrapper>
          <HomeSectionSubtitle>{t('home.blog.miradaDescription')}</HomeSectionSubtitle>
        </HomeSectionHeader>
        <PhotographyList limit={3} />
        <HomeSectionButtonWrapper>
          <Button 
            width={"300px"}
            onClick={() => history.push('/photography')}
            text={`${t('home.callToAction.goTo')} ${photo}`}
          />
        </HomeSectionButtonWrapper>
      </HomeSection>
      <HomeSection>
        <HomeSectionHeader  onClick={() => history.push('/adventures')}>
          <HomeSectionTitleWrapper>
            <HomeSectionTitle>{blogName}</HomeSectionTitle>

            <HomeSectionViewAll>{t('home.blog.viewAll')}</HomeSectionViewAll>
          </HomeSectionTitleWrapper>
          <HomeSectionSubtitle>{t('home.blog.aventuraDescription')}</HomeSectionSubtitle>
          {/* <HomeSectionInfo></HomeSectionInfo> */}
        </HomeSectionHeader>
        <AdventureList limit={3} />
        <HomeSectionButtonWrapper>
          <Button 
            width={"300px"}
            onClick={() => history.push('/adventures')}
            text={`${t('home.callToAction.goTo')} ${blogName}`}
          />
        </HomeSectionButtonWrapper>
      </HomeSection>
      <Space />
      <HomeCopyBox>
      {/* <BannerApp id='app'/> */}
      <NewsletterBox />
      </HomeCopyBox>
      <Space />
    </HomeWrapper>
  );
};

export default Home;