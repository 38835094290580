import styled, { keyframes } from 'styled-components';
import inspirationalImage from './run.jpg'

const grey = '#7d7d7d'
const greyDark = '#444'
const greyLight = '#c7c7c7'

export const HomeWrapper = styled.div``

export const HomeBoxPainPoint = styled.div`
  padding: 80px 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff
`

export const HomeBox = styled.div`
  padding: 50px 10px 200px;
  // height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  :before {
    background-image: url(${inspirationalImage});
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
  }
`

export const HomeBoxSeparator = styled.div`
  width: 100px;
  height: 1px;
  border-radius: 5px;
  background: #830390;
  margin: 20px 0 20px;
`

export const HomeBoxPainPointText = styled.h2`
  font-size: 1.3rem;
  line-height: 33px;
  margin-bottom: 20px;
`

export const HomeBoxPainPointTextItalic = styled.span`
  font-style: italic;
  font-weight: 100;
`

export const HomeBoxPainPointFormWrapper = styled.div`
  margin: 100px 0 0
`

export const HomeBoxPainPointTextWrapper = styled.div`
  margin: 20px 0
`
export const HomeBoxPainPointTextSpecial = styled.h2`
  font-family: 'Averia Serif Libre', sans-serif;
  font-size: 1.5rem;
  font-weight: 100;
  margin-top: 30px
`

export const HomeBoxTitle = styled.h1`
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 33px;
  // max-width: 500px;
  margin-top: 30px;
  margin-bottom: 20px;
  color: #fff;
`

export const HomeBoxTitleBold = styled.span`
  font-weight: 500;
`

export const HomeBoxSubtitle = styled.p`
  margin: 30px 0;
`
export const HomeBoxPurpose = styled.span`
  display: block;
  font-size: 0.7rem;
  font-weight: 100;
  margin-bottom: 10px;
  color: #000;
`

export const HomeBoxPurposeBrand = styled.span`
  font-family: 'Averia Serif Libre', sans-serif;
  color: #830390;
`

export const HomeCopyBrand = styled.span`
  font-family: 'Averia Serif Libre', sans-serif;
  font-size: 18px;
  color: #830390;
`

export const HomeSection = styled.div`
  padding: 50px 10px 0;
  margin: 0 20px;
  @media(max-width: 500px) {
    padding: 50px 0 0;
    margin: 0 20px;
  }
`
export const HomeSectionHeader = styled.div`
  text-align: left;
  margin-bottom: 10px;
  max-width: fit-content;
  &:hover {
    cursor: pointer;
    opacity: .8;
  }
`
export const HomeSectionTitleWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 10px;
`
export const HomeSectionTitle = styled.h3`
  font-family: 'Averia Serif Libre', sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
`
export const HomeSectionViewAll = styled.span`
  font-size: .8rem;
  font-weight: bold;
  border-bottom: 1.5px solid #000;
  transition: .2s;
  margin-left: 15px;
  margin-bottom: 5px;
  &:hover {
    border-color: transparent;
  }
  @media(max-width: 430px) {
    margin-left: 5px;
  }

`
export const HomeSectionSubtitle = styled.h2`
  font-size: 1.3rem;
`

export const HomeBoxPreTitle = styled.p`
font-family: 'Lora', sans-serif;
  font-size: 2rem;
  font-weight: 100;
  margin-bottom: 15px;
  color: #fff;
`
export const HomeSectionInfo = styled.p`
  font-size: .8rem;
  margin-bottom: 10px;
  font-weight: 300;
  margin-top: 10px;
  color: #444;
`

export const HomeBrandName = styled.span`
  font-size: 1.3rem;
  font-family: 'Averia Serif Libre', sans-serif;
  color: #830390;
`

export const HomeSectionButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const Space = styled.div`
  height: 60px;
`


export const HeaderClaimWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 150px 10px 20px;
`;

export const HeaderClaimText= styled.span`
display: block;
font-family: 'Averia Serif Libre', sans-serif;
font-weight: 100;
font-size: 2.4rem;
  color: #fff;
  margin-bottom: 10px;
  @media(max-width: 430px) {
    font-size: 2.7rem;
  }
`;
export const HeaderSpecialText = styled.p`
  // font-family: 'Averia Serif Libre', sans-serif;
  font-weight: 700;
  color: #fff;
  font-size: 2rem;
  margin-bottom: 10px;
  @media(max-width: 430px) {
    font-size: 1.8rem;
  }
`;

export const HeaderSpecialHumanText = styled.p`
  font-family: 'Averia Serif Libre';
  font-weight: 100;
  letter-spacing: .8px;
  color: #fff;
  color: #830390;
  font-size: 1.8rem;
  margin-top: 20px;
  @media(max-width: 430px) {
    font-size: 1.8rem;
    // margin: 10px 0  80px;
  }
`;

export const HomeBoxPurposeDetails = styled.p`
  font-family: 'Lora', sans-serif;
  color: #000;
  font-weight: 300;
  font-size: 1.2rem;
  margin-bottom: 5px;
  margin-bottom: 10px;
  @media(max-width: 430px) {
    font-size: 2.7rem;
  }
`;

export const HomeBoxPurposeDetailsChange = styled.span`
  font-family: 'Grandiflora One', sans-serif;
  font-family: 'Lora', sans-serif;
  color: red;

`;
export const HomeBoxPurposeDetailsTravel = styled.p`
  font-family: 'Grandiflora One', sans-serif;
  color: #000;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 50px;
  @media(max-width: 430px) {
    font-size: 2.7rem;
  }
`;

export const HomeSectionInfoText = styled.span`
  font-size: 11px;
  margin: 0 0 10px 10px;
`;

export const HomeSpecialText = styled.span`
  font-weight: 600;
`;

export const HomeCopyBox = styled.div`
  background: #fff;
  padding: 5px 10px
`;

export const HomeCopyText = styled.p`
  font-size: 18px;

  line-height: 24px;
  max-width: 500px;
  margin: 0 auto 20px;
  font-weight: 100;
  text-align: left
`;

export const HomeSpace = styled.div`
  margin: 50px 0;

`;

export const NewsletterHeaderBoxWrapper = styled.div`
  margin-top: 50px;
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-item: center;
`;

export const NewsletterExample = styled.div`
  background: linear-gradient(rgba(255,255,255, 0), 10%, #fff);
  padding: 0 20px 30px;
  margin: 0 20px 30px;

`;

export const NewsletterExampleText = styled.p`
  font-family: 'Averia Serif Libre', sans-serif;
  font-size: 1rem;
  font-weight: 100;
  margin-top: 30px;
`;

export const NewsletterExampleBlack = styled.span`
  font-weight: bold;
  font-size: 1.1rem;
  font-family: 'Averia Serif Libre', sans-serif;

`;

export const NewsletterExamplRed = styled.span`
  color: red;
  font-family: 'Averia Serif Libre', sans-serif;

`;