import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BeyondTravelInput from '../../components/BeyondTravelInput/BeyondTravelInput'
import atemporal from './images/atemporal.png'
import cirqa from './images/cirqa.png'
import cruz from './images/cruz-del-vado.png'
import fausto from './images/fausto.png'
import pristine from './images/pristine.png'
import titilaka from './images/titilaka.png'
import boaboa from './images/boaboa.jpg'
import elbencabanas from './images/elbencabanas.jpg'
import sureno from './images/sureno.jpg'

import  { 
  HospitalityWrapper,
  HospitalityDescription,
  HospitalityTitle,
  HospitalityDescriptionItalic,
  HospitalityDescriptionBold,
  HospitalityDescriptionArea,
  HospitalityDescriptionUl,
  HospitalityDescriptionLi,
  HospitalityInfonArea,
  HospitalityDescriptionBoldBlock,
  HospitalityDescriptionBlock,
  HospitalityHotelItem,
  HospitalityHotelImg,
  HospitalityHotelWrapper,
  HospitalitySelectionTitle,
  HospitalitySelectionTitleBold,
  HospitalitySelectionText,
  HospitalityContactWrapper,
  HospitalityContactText,
  HospitalityContactTextBold,
  HospitalityContactTextBg
} from './Hospitality.styles'

const Hospitality = () => {

  const { t } = useTranslation()


  return (
    <HospitalityWrapper>
      <HospitalityTitle>
        Contenido, contenido y más contenido
      </HospitalityTitle>
      <HospitalityDescription>
      <HospitalityDescriptionBold>
        Si trabajas en el equipo de Marketing...
      </HospitalityDescriptionBold>
      </HospitalityDescription>
      <HospitalityDescription>
        sabes lo valioso y rentable de <HospitalityDescriptionBold>alimentar de contenido</HospitalityDescriptionBold> las Redes Sociales.
      </HospitalityDescription>
      <HospitalityDescription>
        Contenido de fotografías que seduzcan al potencial cliente a pulsar <HospitalityDescriptionBold>el botón Reservar Ahora</HospitalityDescriptionBold>.
      </HospitalityDescription>
      <HospitalityDescription>
        Pero <HospitalityDescriptionBold>la creatividad se agota</HospitalityDescriptionBold>, es tedioso tomar fotos del mismo espacio una y otra y otra vez.
      </HospitalityDescription>
      <HospitalityDescription>
        Contratar sesiones de fotografía cada vez que queda vacía la biblioteca de imágenes <HospitalityDescriptionBold>está fuera del presupuesto</HospitalityDescriptionBold>.
      </HospitalityDescription>

      <HospitalityDescriptionArea>
      <HospitalityDescription>
        <HospitalityDescriptionItalic>
          ¿Y vosotros cómo podéis ayudarme?
        </HospitalityDescriptionItalic>
      </HospitalityDescription>
      </HospitalityDescriptionArea>
      
      <HospitalityDescription>
      <HospitalityDescriptionBold>Te proponemos una colaboración fotográfica.</HospitalityDescriptionBold>
      </HospitalityDescription>
      <HospitalityDescription>
      <HospitalityDescriptionBold>Te ofrecemos X número de fotografías realizadas por dos ojos que han capturado</HospitalityDescriptionBold>
      </HospitalityDescription>
      <HospitalityDescription>
        <HospitalityDescriptionBold>hoteles de alta categoría en diferentes paises del mundo.</HospitalityDescriptionBold>
      </HospitalityDescription>
      <HospitalityDescription>
        Y, por si fuera poco, son <HospitalityDescriptionBold>ojos de arquitecta y fotógrafa</HospitalityDescriptionBold>.
      </HospitalityDescription>
      <HospitalityDescription>
      (términos a especificar en un carteo privado)
       </HospitalityDescription>
       
       <HospitalityInfonArea>
        <HospitalityDescription>
          Para poder realizar esta colaboración existen <HospitalityDescriptionBold>dos puntos a cumplir</HospitalityDescriptionBold>:
        </HospitalityDescription>
        <HospitalityDescription>
          <HospitalityDescriptionBold>
            1. Sólo trabajamos con hoteles que cumplan ciertos estándares de calidad:
          </HospitalityDescriptionBold>
          <HospitalityDescriptionUl>
            <HospitalityDescriptionLi>
            • Ubicación única.
            </HospitalityDescriptionLi>
            <HospitalityDescriptionLi>
            • Autenticidad en la decoración y diseño.
            </HospitalityDescriptionLi>
            <HospitalityDescriptionLi>
            • Excelencia en la hospitalidad y servicio.
            </HospitalityDescriptionLi>
          </HospitalityDescriptionUl>
        </HospitalityDescription>
        <HospitalityDescription>
          <HospitalityDescriptionBold>
            <HospitalityDescriptionBlock>
            2. Al encontrarnos viajando por el mundo es evidente que el hotel se encuentre o pueda estar dentro de nuestro plan de viaje. 
            </HospitalityDescriptionBlock> 
          </HospitalityDescriptionBold> 
            Por ejemplo, si eres la directora de marketing de un hotel boutique en la Costa Azul francesa no podremos ofrecerte el servicio. 
          <HospitalityDescriptionBlock>
            Si tienes alguna duda de si visitaremos tu país envía tu consulta al correo electrónico de abajo.
          </HospitalityDescriptionBlock>
        </HospitalityDescription>
        {/* <BeyondTravelInput /> */}
      </HospitalityInfonArea>
        <HospitalityContactWrapper>
          <HospitalityContactText>
          <HospitalityContactTextBg>
            Aquí te dejamos nuestro correo de contacto: <HospitalityContactTextBold>contact@emptyroadproject.com</HospitalityContactTextBold>
          </HospitalityContactTextBg>
          </HospitalityContactText>
        </HospitalityContactWrapper>
      <HospitalitySelectionTitle>
        <HospitalitySelectionTitleBold>Selección de Hoteles Boutique</HospitalitySelectionTitleBold> que ya han usado nuestras fotografías en <HospitalitySelectionTitleBold>Instagram</HospitalitySelectionTitleBold>, <HospitalitySelectionTitleBold>Tripadvisor</HospitalitySelectionTitleBold> y <HospitalitySelectionTitleBold>web</HospitalitySelectionTitleBold>. 
      </HospitalitySelectionTitle>
      <HospitalitySelectionText>
        <HospitalitySelectionTitleBold>Click</HospitalitySelectionTitleBold> en cada uno para abrir PDF del porfolio. 
      </HospitalitySelectionText>
      <HospitalityHotelWrapper>
        <a href="https://erp-hospitality.s3.eu-west-1.amazonaws.com/ERP-Revista-Sureno.pdf" target="_blank">
          <HospitalityHotelItem>
            <HospitalityHotelImg src={sureno} />
          </HospitalityHotelItem>
        </a>
        <a href="https://erp-hospitality.s3.eu-west-1.amazonaws.com/ERP-Revista-BoaBoa.pdf" target="_blank">
          <HospitalityHotelItem>
            <HospitalityHotelImg src={boaboa} />
          </HospitalityHotelItem>
        </a>
        <a href="https://erp-hospitality.s3.eu-west-1.amazonaws.com/ERP-Reportaje-Andean-Atemporal.pdf" target="_blank">
          <HospitalityHotelItem>
            <HospitalityHotelImg src={atemporal} />
          </HospitalityHotelItem>
        </a>
        <a href="https://erp-hospitality.s3.eu-west-1.amazonaws.com/ERP-Reportaje-Andean-Cirqa.pdf" target="_blank">
          <HospitalityHotelItem>
            <HospitalityHotelImg src={cirqa} />
          </HospitalityHotelItem>
        </a>
        <a href="https://erp-hospitality.s3.eu-west-1.amazonaws.com/ERP-Reportaje-CruzDelVado.pdf" target="_blank">
          <HospitalityHotelItem>
            <HospitalityHotelImg src={cruz} />
          </HospitalityHotelItem>
        </a>
        <a href="https://erp-hospitality.s3.eu-west-1.amazonaws.com/ERP-Reportaje-Andean-Fausto.pdf" target="_blank">
          <HospitalityHotelItem>
            <HospitalityHotelImg src={fausto} />
          </HospitalityHotelItem>
        </a>
        <a href="https://erp-hospitality.s3.eu-west-1.amazonaws.com/ERP-Revista-Pristine.pdf" target="_blank">
          <HospitalityHotelItem>
            <HospitalityHotelImg src={pristine} />
          </HospitalityHotelItem>
        </a>
        <a href="https://erp-hospitality.s3.eu-west-1.amazonaws.com/ERP-Revista-ElBenCabanas.pdf" target="_blank">
          <HospitalityHotelItem>
            <HospitalityHotelImg src={elbencabanas} />
          </HospitalityHotelItem>
        </a>
      </HospitalityHotelWrapper>
      <HospitalityContactWrapper>
          <HospitalityContactText>
            <HospitalityContactTextBg>
              De nuevo, para cualquier cosa: <HospitalityContactTextBold>contact@emptyroadproject.com</HospitalityContactTextBold>
            </HospitalityContactTextBg>
          </HospitalityContactText>
        </HospitalityContactWrapper>
    </HospitalityWrapper>
  );
};

export default Hospitality;