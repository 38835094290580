import styled from 'styled-components';

const grey = '#7d7d7d'
const greyDark = '#444'
const greyLight = '#c7c7c7'


export const NavbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: ${({ isNavbarBackground }) => isNavbarBackground ? '#fff' : 'transparent'};
  // ${({ isNavbarBackground }) => isNavbarBackground && `
  //   box-shadow: 1px 1px 10px rgba(0,0,0, .1);
  // `};
  transition: background-color .5s linear;
  a {
    text-decoration: none;
  }
  @media(max-width: 430px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`

export const NavbarBrandWrapper = styled.div`
   ${({ isHome }) => isHome && `
    a {
      cursor: default;
    };
  `};
   ${({ isHome }) => !isHome && `
      &:hover a{
        opacity: .8
      }
  `};
`

export const NavbarBrand = styled.div`
  display: flex;
  align-items: center;
`

export const NavbarBrandName = styled.span`
  margin-right: 5px;
  margin-left: 6px;
  color: #830390;
  font-weight: bold;
  font-size: 1rem;
  font-family: 'Averia Serif Libre', sans-serif;
  display: inline-block;
`

export const Logo = styled.img`
  width: 65px;
`;

export const MenuWrapper = styled.div`
  @media(max-width: 1000px) {
    display: none
  }
`
export const MobileNavToggleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media(min-width: 1000px) {
    display: none
  }
`

export const MenuNavBar = styled.nav`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  @media(max-width: 1000px) {
    display: none
  }
`
export const MenuNavBarList = styled.ul`
  display: flex;
`

export const MenuNavBarListItemWrapper = styled.li`
  margin: 0 20px;
`

export const MenuNavBarListItem = styled.span`
  display: inline-block;
  padding: 10px 0;
  
  cursor: pointer;
  transition: .3s;

    white-space: nowrap;
    font-size: 12px;
    color: ${greyDark};
    font-weight: bold;
    letter-spacing: 1.2px;

    border-bottom: 2px solid transparent;
  &:hover {
color: #000;
    /* border-bottom: 1px solid #000; */
    /* background-color: rgb(199, 199, 199, .6); */
  }
`

export const MenuNavBarListItemInfo = styled.span`
  font-size: 9px;
`

export const MenuNavButton = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border: 1px solid;
  border-color: #830390;
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  margin-left: 10px;
  cursor: pointer;
  :hover {
    background-color: ${greyLight};
  }
  a {
    color: #830390;
  }
`
